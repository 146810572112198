<template>
  <main class="form-overlay">
    <!-- TODO: All the components that are rendering on form's top area can be moved into one file so that it will be easy to manage. -->
    <div :style="topContentStyle">
      <component
        :is="SurveyPageLanguageSelector"
        :form-id="formId"
        :uuid="uuid"
        :form-elements="formElements"
        :styling="styling"
        :copy-of-form-elements="copyOfFormElements"
        :pages="pages"
        :copy-of-pages="copyOfPages"
        :form-translations="translations"
      />

      <CountdownTimer
        v-if="styling.timer.timerEnabled"
        ref="countdown"
        :timer="styling.timer"
        @time-up="handleForceSubmit"
      />
    </div>
    <PgLoader :loading="loading" :color="styling.buttonColor" />
    <EmptyForm v-if="canShowEmptyState && !loading" />
    <div v-if="!canShowEmptyState && !loading" class="w-100 h-100">
      <FormesterLayout
        :layout="layout"
        :styling="styling"
        :is-preview="isPreview"
        :preview-width="previewWidth"
        :form-max-width="styling.maxWidth"
        :device="device"
        :page-index="currentPage"
        :current-page-styling="currentPageStyling"
        :is-free-user="isFreeUser"
        :total-pages="pageIds.length"
        :page-ids="pageIds"
        :show-navigation-buttons="showNavigationButtons"
        :show-progress-indicator="showProgressIndicator"
        @prev="onPrev"
        @next="onNext"
      >
        <template #formester-form>
          <div
            class="d-flex flex-column align-items-center w-100 overflow-auto"
            :style="formContainterStyles"
            :class="{ 'scroll-up-with-image': layout !== 'full' && !isPreview }"
          >
            <BrandLogo
              :logo-image="styling.logoImage.image"
              :in-form-logo="styling.inFormLogo"
              :logo-box-color="styling.logoBoxColor"
              :logo-size="styling.logoSize"
            />
            <form
              ref="form"
              :style="formStyles"
              @submit.stop.prevent="onSubmit"
            >
              <transition-group
                :name="pageTransitionName"
                :css="styling.multiPageTransition"
                :duration="300"
                @after-leave="transitionEnd"
              >
                <!-- key: "currentPage" is different for each page. "submitted" is to differentiate the single-page form and the thank-you page -->
                <div
                  :key="`page-${currentPage}-${submitted}`"
                  ref="currentPageDiv"
                  :class="{
                    'space-for-branding': addBrandingSpace,
                    'position-relative': true,
                  }"
                  :style="multiPageFormStyle"
                >
                  <transition-group name="expand-collapse" @leave="handleLeave">
                    <template
                      v-for="item in formesterPages[currentPage]"
                      :key="item.id"
                    >
                      <FormComponent
                        :components="components"
                        :uuid="uuid"
                        :form-id="formId"
                        :submission-uuid="formSessionId"
                        :item="item"
                        :styling="styling"
                        :form-elements="formElements"
                        :variables="variables"
                        :compressor="compressor"
                        :has-multiple-fields="
                          formesterPages[currentPage].length > 1
                        "
                        :device="device"
                        :is-preview="isPreview"
                        @next="onNext"
                        @verified="recaptchaVerified"
                        @expired="recaptchaExpired"
                        @error="recaptchaError"
                        @form-value-changed="handleFormValueChange"
                        @set-page-height="setPageHeight"
                      />
                    </template>

                    <!-- Submit: Only render in single page form -->
                    <component
                      :is="components['submit']"
                      v-if="showSubmitComponent && !submitItem.isHidden"
                      :item="submitItem"
                      :current-page-styling="currentPageStyling"
                      :submitting="submitting"
                      :styling="styling"
                      :settings="settings"
                      :submission-uuid="formSessionId"
                      @save-draft="handleSaveDraft"
                    />

                    <!-- Page Break -->
                    <PageBreak
                      v-if="showMultiPageButton && !submitItem.isHidden"
                      :submit-item="submitItem"
                      :submitting="submitting"
                      :styling="styling"
                      :current-page="currentPage"
                      :current-page-styling="currentPageStyling"
                      :total-pages="pageIds.length"
                      :pages="pages"
                      :settings="settings"
                      :submission-uuid="formSessionId"
                      @prev="onPrev"
                      @next="onNext"
                      @save-draft="handleSaveDraft"
                    />
                  </transition-group>
                </div>
              </transition-group>
              <!-- This div is for the elements which can be hidden and but still be rendered on the DOM -->
              <div v-show="false">
                <template
                  v-for="(item, index) in hiddenDOMElements"
                  :key="item.id"
                >
                  <!-- Calculation -->
                  <component
                    :is="components['calculation']"
                    :index="index"
                    :styling="styling"
                    :item="item"
                    :form-elements="formElements"
                    :variables="variables"
                  />
                </template>
              </div>
            </form>
          </div>
        </template>
      </FormesterLayout>
    </div>

    <component
      :is="components['invisible-recaptcha']"
      v-if="styling.addInvisibleRecaptcha"
      @recaptcha-token="setRecaptchaToken"
    />

    <ComplexStyles :styling="styling" />

    <UniversalFontPicker v-show="false" :model-value="styling.fontFamily" />

    <ElementModal
      ref="resumeSubmissionModal"
      close-on-click-away
      :show-header="false"
      :show-close-icon="false"
      :align-center="false"
      width="400"
    >
      <div class="resume-submission-modal__content-wrapper">
        <div class="alert-icon__wrapper">
          <img
            src="@/assets/images/alert-circle.svg"
            alt="Alert Icon"
            class="alert__icon"
          />
        </div>
        <p class="resume-submission-text">
          You have a submission in progress. Would you like to continue?
        </p>

        <div class="d-flex gap-3 w-100">
          <button
            class="resume-submission-modal__btn button__secondary"
            @click="resetSubmission"
          >
            Reset
          </button>
          <button
            class="resume-submission-modal__btn button__primary"
            @click="handleCloseResumeSubmissionModal"
          >
            Continue
          </button>
        </div>
      </div>
    </ElementModal>

    <ElementModal
      v-if="settings.saveAndResume"
      ref="saveDraftModal"
      close-on-click-away
      title="Your progress has been saved"
      description="Copy or meail the link below and return to your form to complete your submission later"
      :align-center="false"
      width="480"
    >
      <div class="save-draft-modal__content d-flex flex-column gap-3">
        <label class="d-flex flex-column save-draft-modal__label">
          Survey Link
          <div class="d-flex gap-3">
            <input
              type="text"
              class="save-draft-modal__input"
              :value="settings.surveyUrl"
              readonly
            />
            <button
              type="button"
              class="save-draft-modal__btn"
              :style="{ color: buttonTextColor }"
              @click="copySurveyLink"
            >
              Copy
            </button>
          </div>
        </label>

        <label
          v-if="settings.saveAndResumeMailAccountId"
          class="d-flex flex-column save-draft-modal__label"
        >
          Email
          <div class="d-flex gap-3">
            <input
              ref="saveDraftEmailInput"
              type="email"
              class="save-draft-modal__input"
              placeholder="your.email@gmail.com"
            />
            <button
              type="button"
              class="save-draft-modal__btn"
              :style="{ color: buttonTextColor }"
              :disabled="isSendingEmail"
              @click="sendResumeLinkToEmail"
            >
              Send
              <i
                v-if="isSendingEmail"
                class="fa-solid fa-circle-notch fa-spin"
              />
            </button>
          </div>
        </label>
      </div>
    </ElementModal>
  </main>
</template>

<script>
import { defineAsyncComponent } from "vue";

import INTERNAL_QUERY_PARAMS from "@/constants/internalQueryParams";

// components
import UniversalFontPicker from "@formester/universal-font-picker";
import PgLoader from "@/components/shared/loading.vue";
import PageBreak from "./components/page-break.vue";
import ComplexStyles from "./complex-styles.vue";
import EmptyForm from "../../pages/empty-form.vue";
import ElementModal from "@/pages/element-modal.vue";

// To Lazy load components create a method importing the component in importComponents mixin
import importComponentsMixin from "./components/mixins/importComponents";
import FormesterLayout from "@/components/builder/formester-layout.vue";
import BrandLogo from "@/components/builder/brand-logo.vue";
import FormComponent from "./form-component.vue";
import CountdownTimer from "@/components/countdown/timer.vue";

// helpers
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import toNumber from "lodash/toNumber";
import lt from "lodash/lt";
import lte from "lodash/lte";
import gt from "lodash/gt";
import gte from "lodash/gte";
import axios from "axios";
import set from "lodash/set";
import camelCase from "lodash/camelCase";
import debounce from "lodash/debounce";
import { quizOutcome } from "@/helpers/quiz";
import { getFieldId, getVariablesGroupByUuid } from "@/helpers/utils";
import { uploadToS3, getPresignedDataAndUploadToS3 } from "@/utils/s3.js";
import { executeScriptsAndStyles } from "@/helpers/scripts";

import { ElNotification } from "element-plus";
import {
  ACTIONS as conditionAction,
  CALCULATION_ACTIONS as calculationActions,
  TEXT_VARIABLE_ACTIONS as textVariableActions,
} from "./components/mixins/rules";
import OfflineModeFormController from "@/controllers/pwa/offline_mode_form_controller";
import { networkStatus } from "@/utils/checkNetworkStatus.js";
import { createIcons, icons } from "lucide";
import { upsertDraftSubmission } from "@/api/draft_submissions";
import { getPresignedURLForSubmission } from "@/api/submissions.js";
import { getElementsGroupById, addScriptToPage } from "@/helpers/utils";
import { emailSurveyLink } from "@/api/forms";

// composables
import { useSaveAndResumeSubmission } from "@/helpers/composables/use-save-and-resume-submission";
import { useFormElement } from "@/helpers/composables/use-form-element";

export default {
  components: {
    PgLoader,
    PageBreak,
    ComplexStyles,
    UniversalFontPicker,
    EmptyForm,
    FormesterLayout,
    BrandLogo,
    FormComponent,
    ElementModal,
    CountdownTimer,
  },
  mixins: [importComponentsMixin],
  inject: ["formesterAnalytics"],
  props: {
    form: {
      type: Array,
      required: true,
    },
    styling: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      required: false,
      default: "#",
    },
    isPreview: {
      type: Boolean,
      required: true,
    },
    previewWidth: {
      type: String,
      required: false,
      default: "",
    },
    device: {
      type: String,
      required: false,
      default: "",
    },
    uuid: {
      type: String,
      required: true,
    },
    isIframe: {
      type: Boolean,
      required: false,
      default: false,
    },
    formId: {
      type: String,
      required: true,
    },
    prefill: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isFreeUser: {
      type: Boolean,
      default: false,
    },
    hasTrustedFormCertificate: {
      type: Boolean,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    scripts: {
      type: Array,
      default: () => [],
    },
    pages: {
      type: Array,
      default: () => [],
    },
    variables: {
      type: Array,
      required: true,
    },
    translations: {
      type: Array,
      required: true,
    },
    sessionId: {
      type: String,
      default: "",
    },
    surveyUrl: {
      type: String,
      default: "",
    },
  },
  emits: ["submit"],
  setup(props) {
    const { isChoiceType } = useFormElement();
    return {
      ...useSaveAndResumeSubmission(props.uuid, props.sessionId),
      isChoiceType,
    };
  },
  data() {
    return {
      history: [],
      currentPage: 0,
      thankYouPageId: null,
      isHuman: false,
      startTime: "",
      submitting: false,
      invisibleToken: "",
      elements: [],
      loading: true,
      submitted: false,
      formInteraction: false,
      compressor: null,
      formElements: [],
      copyOfFormElements: [],
      copyOfPages: cloneDeep(this.pages),
      pageTransitionName: "next-page",
      isComponentMounted: false,
      SurveyPageLanguageSelector: null,
      outcomePage: null,
      isSendingEmail: false,
    };
  },
  computed: {
    textDirection() {
      if (this.styling.isRtlLanguage) {
        return "rtl";
      } else {
        return "ltr";
      }
    },
    submitItem() {
      return this.form.find((el) => el.type === "submit");
    },
    hasSpamProtection() {
      return this.form.some((field) => field.category === "spam-protection");
    },
    hasAutoId() {
      return this.form.some((field) => field.type === "auto-id");
    },
    formStyles() {
      const styles = {
        boxSizing: "border-box",
        width: "100%",
        maxWidth: `${this.previewWidth || this.styling.maxWidth || 750}px`,
        height: "fit-content",
        margin: "auto",
        borderRadius: "5px",
        zIndex: 3,
        position: "relative",
        direction: this.textDirection,
      };
      return styles;
    },
    addBrandingSpace() {
      return this.isFreeUser
        ? `${Number(this.styling.formVerticalPadding || 16) + 52}px` //52 is the height of branding
        : `${this.styling.formVerticalPadding || 16}px`;
    },
    formContainterStyles() {
      const styles = { height: "100%", "z-index": 1 };
      styles.backgroundColor =
        this.layout === "full" && this.currentPageStyling.backgroundImage.image
          ? "transparent"
          : this.styling.backgroundColor || "#f1f1f1";
      if (
        this.isPreview &&
        this.device !== "desktop" &&
        this.layout !== "full"
      ) {
        styles.overflow = "visible !important";
        styles.height = "auto";
        styles.flexGrow = "1";
      }
      return styles;
    },
    layout() {
      return this.currentPageStyling.pageLayout;
    },
    currentPageId() {
      return this.submitted
        ? this.thankYouPageId
        : this.pageIds[this.currentPage];
    },
    welcomePageId() {
      return this.pages.find((page) => page.type === "welcome")?.id;
    },
    currentPageStyling() {
      const page = this.pages.find((page) => page.id === this.currentPageId);
      return page?.pageStyling || {};
    },
    multiPageFormStyle() {
      return {
        background: this.styling.formColor || "#ffffff",
        width: "100%",
        borderRadius: "5px",
        padding: `${this.styling.formVerticalPadding || 16}px ${
          this.styling.formHorizontalPadding || 8
        }px`,
      };
    },
    pageElementsGroupByPageId() {
      return this.pagesExcludingThankYou.reduce((pageElements, page) => {
        const elements = this.form.filter((el) => {
          if (!isEmpty(el.components)) {
            if (el.isHidden) return false;

            return el.components.some(
              (component) => component.page === page.id && !component.isHidden,
            );
          }

          return el.page === page.id && !el.isHidden;
        });

        if (isEmpty(elements)) {
          return pageElements;
        }

        pageElements[page.id] = elements;
        return pageElements;
      }, {});
    },
    pageElements() {
      return Object.values(this.pageElementsGroupByPageId);
    },
    pageIds() {
      return Object.keys(this.pageElementsGroupByPageId);
    },
    thankYouPageElements() {
      const thankYouPageElements = {};
      this.pages
        .filter((page) => page.type === "thank-you")
        .forEach((page) => {
          thankYouPageElements[page.id] = this.form.filter(
            (el) => el.page === page.id,
          );
        });
      return thankYouPageElements;
    },
    formesterPages() {
      if (this.submitted) {
        return this.thankYouPage;
      }
      return this.pageElements;
    },
    hiddenDOMElements() {
      return this.form.filter((element) => {
        if (!isEmpty(element.components)) {
          return element.components.some(
            (component) =>
              component.isVisibleOnDomIfHidden && component.isHidden,
          );
        }
        return element.isVisibleOnDomIfHidden && element.isHidden;
      });
    },
    singlePage() {
      return !this.styling.multiPage;
    },
    hasQuiz() {
      return this.formElements.some(
        (e) => e.isQuizField && !e.componentFromRepeatField,
      );
    },
    pagesExcludingThankYou() {
      return this.pages.filter(
        (page) => page.type !== "thank-you" && !page.isHidden,
      );
    },
    showSubmitComponent() {
      return this.submitItem && this.pageIds.length === 1 && !this.submitted;
    },
    showMultiPageButton() {
      return this.submitItem && this.pageIds.length > 1 && !this.submitted;
    },
    showProgressIndicator() {
      return (
        this.styling.multiPage &&
        this.styling.showProgressIndicators &&
        this.formElements.length !== 0
      );
    },
    showNavigationButtons() {
      return (
        this.styling.showNavigationButtons &&
        this.showMultiPageButton &&
        !this.submitItem.isHidden
      );
    },
    isTimerEnabled() {
      return this.styling.timer.timerEnabled;
    },
    timerStartPageId() {
      return this.styling.timer.startTimerPageId;
    },
    topContentStyle() {
      const styles = {
        position: "absolute",
        top: "15px",
        right: "15px",
        "z-index": 2,
        display: "flex",
        "align-items": "center",
        gap: "8px",
      };
      if (this.styling.allowOfflineMode && !this.isPreview) {
        styles.right = "66px";
      }
      return styles;
    },
    canShowEmptyState() {
      return this.pageElements
        .flat()
        .every((el) => el.group === "layout" && !el?.components?.length);
    },
    uploadableElements() {
      return this.formElements.filter((el) => el.uploadable);
    },
    variablesGroupByUuid() {
      return getVariablesGroupByUuid(this.variables);
    },
    buttonTextColor() {
      return this.styling.buttonTextColor || "white";
    },
  },
  watch: {
    currentPage: {
      immediate: true,
      handler() {
        // send height to embed standard iframe
        this.setPageHeight();
        this.checkScriptToExecute();
      },
    },
    thankYouPageId: {
      handler() {
        this.setPageHeight();
      },
    },
    form: {
      handler() {
        this.focusFirstAvailableInput();
      },
    },
    prefill: {
      handler() {
        // prefill form data
        if (!isEmpty(this.prefill)) {
          this.prefillFormData(this.prefill);
        }
      },
      deep: true,
    },
  },
  async mounted() {
    window.onbeforeunload = this.onUnload;
    this.formesterAnalytics?.trackPageView(this.currentPageId);

    this.startTime = new Date();
    // dynamically load form components
    await this.importDynamicComponents();

    this.addDefaultValueInVariables();

    // prefill form data
    if (!isEmpty(this.prefill)) {
      this.prefillFormData(this.prefill);
    }

    // handle form resumption
    if (this.styling.enableResumption) {
      const formElements = JSON.parse(localStorage.getItem(this.uuid));
      if (formElements) {
        this.$refs.resumeSubmissionModal.open();
        this.prefillFormData(formElements);
      }
    }

    // Save and resume form
    if (this.settings.saveAndResume && !this.isPreview) {
      const data = await this.getDraftSubmission();
      if (data) this.prefillFormData(data);
    }

    if (this.isTimerEnabled) {
      this.$watch("currentPageId", this.handleCountdown, { immediate: true });
    }

    // Rules
    this.handleShowHideRules();
    this.handleCalculationRules();

    // send height to embed standard iframe
    setTimeout(() => {
      this.setPageHeight();
    }, 0);

    // Send height to embed standard iframe on window resize
    window.addEventListener("resize", this.setPageHeight);

    // Component mounted
    this.loading = false;
    this.isComponentMounted = true;
    if (this.hasTrustedFormCertificate) {
      await addScriptToPage(
        null,
        `(function() {
        var tf = document.createElement('script');
        tf.type = 'text/javascript';
        tf.async = true;
        tf.src = ("https:" == document.location.protocol ? 'https' : 'http') +
          '://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=' +
          new Date().getTime() + Math.random();
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s);
      })();`,
      );
    }
  },
  beforeUnmount() {
    window.onbeforeunload = null;
  },
  unmounted() {
    window.removeEventListener("resize", this.setPageHeight);
  },
  updated() {
    // using Lucide Icons
    createIcons({ icons });
  },
  methods: {
    handleLeave(element) {
      element.style.margin = 0;
      element.style.height = element.scrollHeight + "px";
      element.style.overflow = "hidden";
      element.style.animation = "elementLeave 0.3s ease";
    },
    set,
    camelCase,
    isEmpty,
    recaptchaVerified() {
      this.isHuman = true;
    },
    recaptchaExpired() {
      this.isHuman = false;
    },
    recaptchaError() {
      ElNotification({
        message: "Could not verify ReCaptcha, please try again",
        type: "error",
        position: "bottom-right",
      });
    },
    async importDynamicComponents() {
      const importPromises = this.form
        .filter((el) => el.type !== "page-break")
        .map(async (el) => {
          if (el.components) {
            this.formElements.push(el);
            this.importNestedDynamicComponents(el.components, true);
          } else {
            this.formElements.push(el);
          }

          if (el.compressionEnabled) {
            const module = await import("compressorjs");
            this.compressor = module.default;
          }

          try {
            return this[`${camelCase(el.type)}Import`]();
          } catch (e) {
            console.error(e);
          }
        });

      await Promise.all(importPromises);

      if (this.styling.addInvisibleRecaptcha) {
        await this["invisibleRecaptchaImport"]();
      }

      if (this.styling.enableMultiLanguageSupport) {
        this.SurveyPageLanguageSelector = await defineAsyncComponent(
          () =>
            import(
              "@/components/multi_language/survey-page-language-selector.vue"
            ),
        );
      }

      this.copyOfFormElements = cloneDeep(this.formElements);
    },
    importNestedDynamicComponents(arr) {
      return arr.map((nestedEl) => {
        this.formElements.push(nestedEl);
        try {
          return this[`${camelCase(nestedEl.type)}Import`]();
        } catch (e) {
          console.error(e);
        }
      });
    },
    setNestedValues(subObj, nestedData) {
      for (const i of nestedData) {
        if (i.children) {
          this.setNestedValues(subObj, i.children);
        } else {
          i.error = "";
          subObj[i.id] = i.value;
        }
      }
    },
    addErrorMsg(arr, missing_children) {
      arr.forEach((child) => {
        if (child.children) {
          this.addErrorMsg(child.children, missing_children);
        } else {
          const idx = missing_children.findIndex((i) =>
            i.includes(child.label || child.id),
          );
          if (idx !== -1) {
            child.error = "This is a required field.";
          }
        }
      });
    },
    checkForErrors(arr) {
      for (let i of arr) {
        if (i.children) {
          return this.checkForErrors(i.children);
        } else if (i.error) {
          return true;
        }
      }
    },
    dataURLtoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    setRecaptchaToken(token) {
      this.invisibleToken = token;
    },
    notifyPreviewFormSubmitted() {
      ElNotification({
        message: "Preview Form Submitted",
        type: "success",
        position: "bottom-right",
      });

      setTimeout(() => this.$emit("submit"), 1000);
    },
    prepareFormData() {
      const formData = new FormData();
      this.formElements
        .filter((el) => el.category === "input" && !el.componentFromRepeatField)
        .forEach((el) => {
          this.appendElementToFormData(el, formData);
        });

      if (this.hasSpamProtection) {
        this.formElements.forEach((i) => {
          if (i.type === "recaptcha") {
            formData.append("g-recaptcha-response", i.value);
          } else if (i.type === "cf-turnstile") {
            formData.append("cf-turnstile-response", i.value);
          }
        });
      }

      // Payment Fields
      this.formElements
        .filter((el) => el.category === "payment")
        .forEach((el) => this.appendElementToFormData(el, formData));

      // Repeat Fields
      this.formElements
        .filter((el) => el.type === "repeat-field")
        .forEach((el) => this.appendElementToFormData(el, formData));

      this.variables.forEach((variable) => {
        formData.append(variable.name, variable.value);
      });

      if (this.outcomePage) {
        formData.append("quiz_outcome[id]", this.outcomePage.id);
        formData.append("quiz_outcome[name]", this.outcomePage.name);
      }

      formData.append("loaded_at_for_analytics", this.startTime);
      formData.append("recaptcha_token", this.invisibleToken);
      formData.append("session_id", this.formSessionId);

      if (this.hasTrustedFormCertificate) {
        const data = new FormData(this.$refs.form);
        const trustedFormCertUrl = data.get("xxTrustedFormCertUrl");
        if (trustedFormCertUrl) {
          formData.append("trusted_form_cert_url", trustedFormCertUrl);
        }
      }

      return formData;
    },
    appendElementToFormData(element, formData) {
      const key = element.id;
      if (element.children) {
        const subObj = {};
        this.setNestedValues(subObj, element.children);
        for (const childKey in subObj) {
          formData.set(childKey, subObj[childKey]);
        }
      } else {
        element.error = "";
        if (element.type === "file" && element.value) {
          element.value.forEach((value, i) => {
            if (value.metadata) {
              formData.set(`${key}[${i}]`, JSON.stringify(value));
            } else {
              formData.set(`${key}[${i}]`, value, value.name);
            }
          });
        } else if (element.type === "signature" && element.value) {
          formData.set(key, this.dataURLtoBlob(element.value), "signature");
        } else if (element.type === "phone" && element.value) {
          let value = element.value;
          if (element.showCountrySelector) {
            value = element.selectedCountry.dialCode
              ? `${element.selectedCountry.dialCode}-${element.value}`
              : `+${element.value}`;
          }

          formData.set(key, value);
        } else if (element.type === "matrix") {
          const originalElement = this.copyOfFormElements.find(
            (el) => el.id === element.id,
          );

          const data = {};
          data[key] = {};
          Object.entries(element.value).forEach(([rowId, rowValue]) => {
            const rowLabel = originalElement.rows.find(
              (row) => row.id === rowId,
            )?.label;
            data[key][rowLabel] = {};

            Object.entries(rowValue).forEach(([colId, colValue]) => {
              const colLabel = originalElement.columns.find(
                (col) => col.id === colId,
              )?.label;

              data[key][rowLabel][colLabel] = colValue;
            });
          });
          formData.set(key, JSON.stringify(data[key]));
        } else if (
          (this.isChoiceType(element) || element.type === "ranking") &&
          element.value
        ) {
          if (element.value instanceof Array) {
            this.getChoiceValue(element).forEach((value, i) =>
              formData.set(`${key}[${i}]`, value),
            );
          } else {
            formData.set(key, this.getChoiceValue(element));
          }
        } else if (element.type === "repeat-field") {
          element.rows.forEach((row, i) => {
            row.forEach((col) => {
              const value = col.value;
              if (this.isChoiceType(col) && value) {
                if (value instanceof Array) {
                  formData.delete(`${key}[${i}][${col.id}][]`);
                  this.getChoiceValue(col).forEach((val, j) => {
                    formData.append(`${key}[${i}][${col.id}][]`, val);
                  });
                } else {
                  formData.set(
                    `${key}[${i}][${col.id}]`,
                    this.getChoiceValue(col),
                  );
                }
              } else {
                formData.set(`${key}[${i}][${col.id}]`, value);
              }
            });
          });
        } else if (element.type === "email" && element.confirmEmail) {
          formData.set(`${key}_code`, element.emailConfirmationData.code);
          formData.set(key, element.value);
        } else {
          formData.set(key, element.value);
        }
      }
    },
    getChoiceValue(element) {
      let value = [];
      if (isEmpty(element.value) && element.type === "ranking") {
        return (element.value = element.options.map(
          (option) => option?.label || option.id,
        ));
      }

      const originalElement = this.copyOfFormElements.find(
        (el) => el.id === element.id,
      );

      if (element.value instanceof Array) {
        let i = 0;
        element.value.forEach((optionId, index) => {
          const option = originalElement.options.find(
            (option) => option.id === optionId,
          );

          const optionValue = element.hasCustomValuesForOptions
            ? option?.value
            : "";

          value.push(optionValue || option?.label || optionId);
          i = index;
        });

        if (
          element.hasCustomOption &&
          element.isCustomOptionChecked &&
          element.customOptionValue
        ) {
          value.push(element.customOptionValue);
        }
      } else {
        const option = originalElement.options.find(
          (option) => option.id === element.value,
        );

        const optionValue = element.hasCustomValuesForOptions
          ? option?.value
          : "";

        value = optionValue || option?.label || element.value;
      }

      return value;
    },
    async onSubmit(e, skipValidations = false) {
      let timeTaken;
      if (this.isTimerEnabled) {
        timeTaken = this.$refs.countdown.stopCountdown();
      }

      this.outcomePage = quizOutcome(this.formElements, this.pages);

      if (this.hasSpamProtection && !this.isHuman) {
        ElNotification({
          message: "Verify that your are human",
          type: "error",
          position: "bottom-right",
        });
        return;
      }

      if (this.isPreview) {
        this.notifyPreviewFormSubmitted();
        return;
      }

      this.submitting = true;
      this.filterSubmissionsBasedOnConditions();

      const formData = this.prepareFormData();
      await this.handlePendingFileUploads(formData);

      // add time taken to form data
      if (this.isTimerEnabled && timeTaken) {
        formData.append("quiz_timer_time_taken", timeTaken);
      }

      // handle offline form
      if (this.styling.allowOfflineMode) {
        if (await this.handleOfflineSubmission(formData)) {
          this.submitting = false;
          return;
        }
      }

      const queryParams = {
        status: "complete",
        draft_id: this.formSessionId,
      };

      // Has payment paypal field
      const hasPaypal = this.form.some(
        (el) => el.category == "payment" && el.type == "paypal" && !el.isHidden,
      );
      if (hasPaypal) {
        queryParams.payment_gateway = "paypal";
      }

      const stripeElement = this.form.find(
        (el) => el.category == "payment" && el.type == "stripe" && !el.isHidden,
      );

      if (stripeElement) {
        queryParams.payment_gateway = "stripe";
      }

      if (skipValidations) {
        queryParams.skip_validations = true;
      }

      try {
        const { data } = await axios.post(this.action, formData, {
          params: queryParams,
        });

        if (this.hasAutoId) {
          this.formElements.forEach((el) => {
            if (el.type === "auto-id") {
              el.value = data?.data[el.id] || el.value;
            }
            return el;
          });
        }

        const order = data.order;
        const stripeAuthenticationLink = data.stripe_authentication_link;

        if (order) {
          // order object has links array we need to get approve link to redirect user to payment page
          console.debug("order: ", order);
          window.location.href = order.links[1]?.href;
          return;
        }

        if (stripeAuthenticationLink) {
          window.location.href = stripeAuthenticationLink;
          return;
        }

        this.emitter?.emit("submit");

        // clear local storage if was ever set
        localStorage.removeItem(this.uuid);

        ElNotification({
          message: data?.message || "Form Submitted Successfully",
          type: "success",
          position: "bottom-right",
        });

        if (data.is_template) {
          setTimeout(() => {
            this.submitting = false;
          }, 1000);
          return;
        }

        setTimeout(() => {
          this.$emit("submit", data);
          this.submitting = false;
        }, 1000);
      } catch (error) {
        const response = error.response;
        if (!response) {
          ElNotification({
            message: "Something went wrong, Please try again.",
            type: "error",
            position: "bottom-right",
          });

          console.error(error);
          return;
        }

        this.submitting = false;
        const { message, validations } = response.data;
        for (let err in validations) {
          // Checking for missing fields
          if (err === "missing_fields") {
            this.formElements.forEach((field) => {
              if (
                field.label in validations["missing_fields"] ||
                field.id in validations["missing_fields"]
              ) {
                if (field.children) {
                  this.addErrorMsg(
                    field.children,
                    validations["missing_fields"][field.label || field.id],
                  );
                } else {
                  field.error = "This is a required field.";
                }
              }
            });
          }

          // Checking for other field errors
          this.formElements.forEach((field) => {
            if (field.label === err || field.id === err) {
              field.error = validations[err];
            }
          });
        }

        if (message) {
          ElNotification({
            message: message,
            type: "error",
            position: "bottom-right",
          });
        }

        if (this.pageElements.length !== 1) {
          const idx = this.pageElements.findIndex((page) => {
            return page.some((field) => {
              if (field.children) {
                return this.checkForErrors(field.children);
              } else if (field.components) {
                return this.checkForErrors(field.components);
              } else if (field.error) {
                return true;
              }
            });
          });
          if (idx !== -1) {
            this.currentPage = idx;
          }
        }
      }
    },
    async handleOfflineSubmission(formData) {
      await networkStatus.checkOnlineStatus();
      if (networkStatus.online) {
        return false;
      }

      this.submitting = true;
      const offlineModeFormController = new OfflineModeFormController();
      try {
        await offlineModeFormController.saveSubmission(formData, this.action);
        ElNotification({
          message:
            "Form Submitted Successfully. It will be synced to the server when you have an internet connection.",
          type: "success",
          position: "bottom-right",
        });

        this.submitting = false;
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (e) {
        console.error(e);
        this.submitting = false;
        ElNotification({
          message: "Unable to submit form. Please try again",
          type: "error",
          position: "bottom-right",
        });
      }

      return true;
    },
    async handlePendingFileUploads(formData) {
      await networkStatus.checkOnlineStatus();
      const isOnline = networkStatus.online;

      const uploadPromises = this.formElements.map(async (el) => {
        if (el.type === "file" && el.pendingFiles) {
          const filePromises = el.pendingFiles.map(async (file, index) => {
            let data = {};
            if (isOnline) {
              // Client side file upload
              data = await this.uploadFileToS3(file);
            }

            const length = el.value.length;
            if (data?.metadata) {
              formData.set(`${el.id}[${length + index}]`, JSON.stringify(data));
            } else {
              // Server side file upload
              formData.set(`${el.id}[${length + index}]`, file, file.name);
            }
          });
          await Promise.all(filePromises);
        } else if (el.type === "signature" && el.value) {
          const signature = this.dataURLtoBlob(el.value);
          signature.name = "signature.png";
          let data = {};
          if (isOnline) {
            // Client side file upload
            data = await this.uploadFileToS3(signature);
          }

          if (data?.metadata) {
            formData.set(el.id, JSON.stringify(data));
          } else {
            // Server side file upload
            formData.set(el.id, signature, "signature");
          }
        }
      });

      await Promise.all(uploadPromises);
    },
    async uploadFileToS3(file) {
      const presignedData = await getPresignedDataAndUploadToS3(
        getPresignedURLForSubmission,
        file,
        this.uuid,
        this.formSessionId,
      );

      if (!presignedData) return;

      const attachmentDetails = await uploadToS3(file, presignedData);
      if (!attachmentDetails) return;

      return attachmentDetails;
    },
    onPrev(pageIndex) {
      // change transition name
      this.pageTransitionName = "prev-page";

      this.currentPage = this.history.pop() || 0;
      this.focusFirstAvailableInput();
      this.formesterAnalytics?.trackPageView(this.currentPageId);
    },
    getNestedValue(children, id) {
      for (let child of children) {
        if (child.children) {
          return this.getNestedValue(child.children, id);
        } else if (child.id === id) {
          return child.value;
        }
      }
      return null;
    },
    matchCondition(condition, elementId) {
      let elementIndex = null;
      let value = null;
      let index = 0;
      const conditionFieldValue = condition.field.value;
      const conditionValue = condition.target.value?.toString().toLowerCase();

      for (let i in this.formElements) {
        const element = this.formElements[i];
        elementIndex = element.id === elementId ? i : elementIndex;

        if (element.children) {
          if (conditionFieldValue) {
            value = this.getNestedValue(element.children, conditionFieldValue);
          }
        } else if (element.id === getFieldId(conditionFieldValue)) {
          if (element.type === "matrix") {
            // In case of matrix, condition.field.value will be in the format of matrixId_rowId
            const rowId = conditionFieldValue.split("_")[1];

            const row = element.value[rowId];
            if (row) {
              value = Object.keys(row);
            }
          } else {
            value = element.value;
          }
        }

        if (elementIndex && index > elementIndex) {
          value = null;
        }

        if (value !== null) {
          break;
        }

        index++;
      }

      const variable =
        this.variablesGroupByUuid[getFieldId(conditionFieldValue)];

      if (variable) {
        value = variable[0]?.value;
      }

      value = value?.toString().toLowerCase();

      switch (condition.clause) {
        case "is_equal_to":
          // console.debug(
          //   "matchCondition: ",
          //   value,
          //   "is_equal_to",
          //   conditionValue,
          // );
          return value == conditionValue;
        case "is_not_equal_to":
          // console.debug(
          //   "matchCondition: ",
          //   value,
          //   "is_not_equal_to",
          //   conditionValue,
          // );
          return value != conditionValue;
        case "contains":
          // console.debug("matchCondition: ", value, "contains", conditionValue);
          return value?.includes(conditionValue);
        case "does_not_contain":
          // console.debug(
          //   "matchCondition: ",
          //   value,
          //   "does_not_contain",
          //   conditionValue,
          // );
          return !value?.includes(conditionValue);
        case "is_empty":
          // console.debug("matchCondition: ", value, "is_empty", conditionValue);
          return isEmpty(value);
        case "is_filled":
          // console.debug("matchCondition: ", value, "is_filled", conditionValue);
          return !isEmpty(value);
        // numerical validations
        case "is_less_than":
          // console.debug(
          //   "matchCondition: ",
          //   value,
          //   "is_less_than",
          //   conditionValue,
          // );
          return lt(toNumber(value), toNumber(conditionValue));
        case "is_greater_than":
          // console.debug(
          //   "matchCondition: ",
          //   value,
          //   "is_greater_than",
          //   conditionValue,
          // );
          return gt(toNumber(value), toNumber(conditionValue));
        case "is_less_than_or_equal_to":
          // console.debug(
          //   "matchCondition: ",
          //   value,
          //   "is_less_than_or_equal_to",
          //   conditionValue,
          // );
          return lte(toNumber(value), toNumber(conditionValue));
        case "is_greater_than_or_equal_to":
          // console.debug(
          //   "matchCondition: ",
          //   value,
          //   "is_greater_than_or_equal_to",
          //   conditionValue,
          // );
          return gte(toNumber(value), toNumber(conditionValue));
        default:
          // by default we are saying that none of the conditions are matching and default behaviour should continue
          return false;
      }
    },
    verifyRules(rule, elementId) {
      let ruleSatisfied;
      if (rule.conjunction === "AND") {
        // AND operation needs the default value to be true
        ruleSatisfied = true;
      } else if (rule.conjunction === "OR") {
        // OR operation required the default value to be false
        ruleSatisfied = false;
      }

      // go through different condtion and check if conditions are met
      for (let condition of rule.conditions) {
        if (rule.conjunction === "AND") {
          ruleSatisfied =
            ruleSatisfied && this.matchCondition(condition, elementId);
        } else if (rule.conjunction === "OR") {
          ruleSatisfied =
            ruleSatisfied || this.matchCondition(condition, elementId);
        }
        // console.debug("verifyRules: ", rule.conjunction, ruleSatisfied);
      }

      return ruleSatisfied;
    },
    findTargetPage(id) {
      const pageIndex = Object.keys(this.pageElementsGroupByPageId).indexOf(id);
      console.debug("findTargetPage:", pageIndex);
      return pageIndex;
    },
    onNext(pageIndex) {
      // change transition name
      this.pageTransitionName = "next-page";

      pageIndex = pageIndex || this.currentPage;
      const valid = this.$refs.form.reportValidity();

      if (!valid) return;

      if (pageIndex === this.pageIds.length - 1) return;

      let defaultNextPageIndex = -1;
      const elements = this.pageElements[pageIndex];

      const rules = this.rules.filter((rule) => {
        const { conditions, action, page } = rule;

        return elements.some((element) => {
          if (page && page === element.page) {
            defaultNextPageIndex = this.findTargetPage(action.field.value);
          }

          return (
            conditions.some((condition) => {
              if (condition.field.value?.startsWith("variable_")) {
                return true;
              }

              if (!isEmpty(element.children)) {
                return this.matchChildById(
                  getFieldId(condition.field.value),
                  element.children,
                );
              }
              return getFieldId(condition.field.value) === element.id;
            }) && action.do === conditionAction.skip_to_page_on_next_button
          );
        });
      });

      let ruleSatisfied = false;
      let targetPageIndex;

      for (let rule of rules) {
        ruleSatisfied = this.verifyRules(
          rule,
          elements[elements.length - 1]?.id,
        );
        if (ruleSatisfied) {
          targetPageIndex = this.findTargetPage(rule.action.field.value);
          //  if page not found check in thank you pages
          if (targetPageIndex < 0) {
            const targetPage = this.pages.find(
              (page) => page.id === rule.action.field.value,
            );

            if (targetPage) {
              this.$emit("submit", { thankYouPageId: targetPage.id });
              return;
            }
          }
          break;
        }
      }

      this.history.push(pageIndex);

      if (ruleSatisfied) {
        this.currentPage =
          targetPageIndex !== -1 ? targetPageIndex : pageIndex + 1;
      } else {
        this.currentPage =
          defaultNextPageIndex !== -1 ? defaultNextPageIndex : pageIndex + 1;
      }

      this.formesterAnalytics?.trackPageView(this.currentPageId);
    },
    matchChildById(id, children) {
      children.forEach((child) => {
        if (!isEmpty(child.children)) {
          return this.matchChildById(id, child.children);
        }
        return child.id === id;
      });
    },
    transitionEnd() {
      this.focusFirstAvailableInput();
    },
    focusFirstAvailableInput() {
      if (this.isIframe) {
        return;
      }
      this.$nextTick(() => {
        const input = document.querySelector(".formester-input");
        input && input.focus();
      });
    },
    resetCurrentPage() {
      this.currentPage = 0;
    },
    formSubmitted(thankYouPageId) {
      this.submitted = true;
      // When we allow multiple thankYou and render a thank you page conditionally
      // check condition here and assign it to thankYouPage
      let thankYouPage;
      if (thankYouPageId) {
        thankYouPage = this.thankYouPageElements[thankYouPageId];
      } else {
        thankYouPage =
          this.validateThankYouRules() ||
          Object.values(this.thankYouPageElements)[0];
      }

      const thankYouPageIds = this.pages
        .filter((page) => page.type === "thank-you")
        .map((page) => page.id);

      const jumpToThankYouPageRules = this.rules.filter(
        (rule) =>
          rule.action.do === conditionAction.skip_to_page_on_next_button &&
          thankYouPageIds.includes(rule.action.field.value),
      );

      if (jumpToThankYouPageRules.length) {
        for (let rule of jumpToThankYouPageRules) {
          if (this.verifyRules(rule)) {
            thankYouPage = this.thankYouPageElements[rule.action.field.value];
            break;
          }
        }
      }

      this.thankYouPage = [thankYouPage];
      this.thankYouPageId = thankYouPage[0].page;
    },
    prefillFormData(data) {
      for (const key in data) {
        this.formElements.forEach((el) => {
          this.traverseAndPrefill(el, key, data);
        });
      }
    },
    traverseAndPrefill(obj, key, data) {
      if (obj.label?.toLowerCase() === key.toLowerCase() || obj.id === key) {
        if (obj.type === "phone") {
          const number = data[key].includes("-")
            ? data[key].split("-")[1]
            : data[key];
          obj.value = number;
          obj.formattedValue = number;
        } else if (obj.type === "file") {
          obj.value = data[key].map((fileObject) => {
            if (fileObject.hasOwnProperty("metadata")) {
              return fileObject;
            }

            const blob = new Blob([], { type: fileObject.type });
            // Construct a File object using the Blob and additional properties
            const file = new File([blob], fileObject.name, {
              lastModified: fileObject.lastModified,
              type: fileObject.type,
            });
            return file;
          });
        } else if (obj.type === "ranking") {
          obj.value = data[key].map(
            (option) =>
              obj.options.find((o) => o.label === option || o.id === option)
                ?.id,
          );
        } else if (obj.type === "matrix") {
          obj.value = {};
          Object.entries(data[key]).forEach(([rowLabel, rowValues]) => {
            const rowId = obj.rows.find(
              (row) => row.label === rowLabel || row.id === rowLabel,
            )?.id;

            if (rowId) {
              obj.value[rowId] = {};
              Object.entries(rowValues).forEach(([colLabel, colValue]) => {
                const colId = obj.columns.find(
                  (col) => col.label === colLabel || col.id === colLabel,
                )?.id;
                if (colId) {
                  obj.value[rowId][colId] = colValue;
                }
              });
            }
          });
        } else if (this.isChoiceType(obj) && obj.options) {
          if (obj.value instanceof Array) {
            const otherOptions = [];
            let options = Array.isArray(data[key])
              ? data[key]
              : data[key].split(",");
            options = options.map((value) => {
              const option = obj.options.find(
                (o) => o.label === value || o.id === value,
              );

              if (!option) {
                otherOptions.push(value);
              }

              return option?.id;
            });

            options = options.filter((v) => !!v);

            // if has other option
            if (otherOptions.length && obj.hasCustomOption) {
              obj.isCustomOptionChecked = true;
              obj.customOptionValue = otherOptions.join(", ");
            }

            obj.value = options;
          } else {
            const option = obj.options.find(
              (option) =>
                data[key].includes(option.label) ||
                data[key].includes(option.id),
            );

            if (!option && data[key]) {
              obj.isCustomOptionChecked = true;
            }

            obj.value = option?.id || data[key];
          }
        } else if (obj.type === "repeat-field") {
          if (data[key]) {
            const rows = [];
            data[key].forEach((row, index) => {
              rows.push(cloneDeep(obj.components));
              Object.entries(row).forEach(([colId, colValue]) => {
                const col = rows[index].find(
                  (component) => component.id === colId,
                );

                if (col) {
                  col.value = colValue;
                }
              });
            });

            obj.rows = rows;
          }
        } else if (obj.category === "payment") {
          obj.amount = data[key];
        } else {
          obj.value = data[key];
        }
      } else if (!isEmpty(obj.children)) {
        obj.children.forEach((child) =>
          this.traverseAndPrefill(child, key, data),
        );
      } else if (obj.type === "column-layout") {
        obj.components.forEach((component) => {
          this.traverseAndPrefill(component, key, data);
        });
      }
    },
    handleShowHideRules() {
      const formElementMap = getElementsGroupById(this.formElements);

      // Resetting hidden fields and validating rules in a single loop
      for (const rule of this.rules) {
        const isHideField = rule.action.do === conditionAction.hide_field;
        const isShowField = rule.action.do === conditionAction.show_field;

        if (isHideField || isShowField) {
          const ruleSatisfied = this.verifyRules(rule);

          for (const value of rule.action.field.values) {
            const targetElement = formElementMap[value];
            if (targetElement) {
              if (isHideField) {
                targetElement.isHidden = ruleSatisfied;
              } else if (isShowField) {
                targetElement.isHidden = !ruleSatisfied;
              }
            }
          }
        }
      }
    },
    validateThankYouRules() {
      let page;
      const thankYouPageRules = this.rules.filter(
        (rule) =>
          rule.action.do === conditionAction.show_different_thank_you_page,
      );

      if (thankYouPageRules.length) {
        for (let rule of thankYouPageRules) {
          let ruleSatisfied = false;
          ruleSatisfied = this.verifyRules(rule);
          if (ruleSatisfied) {
            page = this.pages.find(
              (page) =>
                page.id === rule.action.field.value &&
                page.type === "thank-you",
            );
            break;
          }
        }
      } else {
        page = this.outcomePage;
      }
      return this.thankYouPageElements[page?.id];
    },
    filterSubmissionsBasedOnConditions() {
      for (const rule of this.rules) {
        const conditionValid = this.verifyRules(rule);

        if (conditionValid) {
          if (rule.action.do === conditionAction.hide_field) {
            this.clearFields(rule.action.field.values);
          } else if (
            rule.action.do === conditionAction.skip_to_page_on_next_button
          ) {
            this.skipToPage(rule);
          }
        } else {
          if (rule.action.do === conditionAction.show_field) {
            this.clearFields(rule.action.field.values);
          }
        }
      }
    },
    clearFields(fieldIds) {
      fieldIds.forEach((fieldId) => {
        const fieldIndex = this.formElements.findIndex((e) => e.id === fieldId);
        if (fieldIndex !== -1) {
          this.formElements[fieldIndex].value = "";
          this.clearChildFields(this.formElements[fieldIndex]);
        }
      });
    },
    clearChildFields(field) {
      field.value = "";
      if (Array.isArray(field?.children)) {
        field.children.forEach(this.clearChildFields);
      }
    },
    skipToPage(rule) {
      // Find the current page based on the last matching field
      const currentField = this.findLastMatchingField(rule.conditions);
      const currentPageId = currentField?.page;

      const currentPageIndex = this.pages.findIndex(
        (page) => page.id === currentPageId,
      );
      const skipToPageIndex = this.pages.findIndex(
        (page) => page.id === rule.action.field.value,
      );

      if (currentPageIndex !== -1 && skipToPageIndex !== -1) {
        const targetPages = this.pages.slice(
          currentPageIndex + 1,
          skipToPageIndex,
        );

        if (targetPages) {
          this.clearFieldsForPages(targetPages);
        }
      }
    },
    clearFieldsForPages(pages) {
      for (const el of this.formElements) {
        const elPageId = el.page;

        if (pages.some((page) => page.id === elPageId)) {
          el.value = "";
        }
      }
    },
    findLastMatchingField(conditions) {
      const conditionFieldValues = conditions.map(
        (condition) => condition.field.value,
      );
      let lastMatchingField = null;
      let highestIndex = -1;

      this.formElements.forEach((field, index) => {
        if (conditionFieldValues.includes(field.id) && index > highestIndex) {
          highestIndex = index;
          lastMatchingField = field;
        }
      });

      return lastMatchingField;
    },

    handleFirstInteraction() {
      if (!this.formInteraction) {
        this.emitter?.emit("firstInteraction");
        this.formInteraction = true;
      }
    },
    handleCalculationRules() {
      // Resetting the variables value
      this.variables.forEach((variable) => {
        variable.value = variable.defaultValue;
      });

      // Handle variable calculations and text variable actions
      for (const rule of this.rules) {
        const isCalculationAction = calculationActions[rule.action.do];
        const isTextVariableAction = textVariableActions[rule.action.do];
        const variableId = rule.action.to?.value;
        const targetValue = rule.action.field.value;

        if (isCalculationAction || isTextVariableAction) {
          const ruleSatisfied = this.verifyRules(rule);

          if (ruleSatisfied) {
            let variable = this.variablesGroupByUuid[variableId];

            if (variable && variable.length) {
              variable = variable[0];
              if (isCalculationAction) {
                variable.value = this.calculateVariable(
                  rule.action.do,
                  variable.value,
                  targetValue,
                );
              }

              if (isTextVariableAction) {
                variable.value = targetValue;
              }
            }
          }
        }
      }
    },
    calculateVariable(actionDo, variableValue, targetValue) {
      variableValue = Number(variableValue);
      targetValue = Number(targetValue);

      switch (actionDo) {
        case "add":
          return (variableValue += targetValue);
        case "subtract":
          return (variableValue -= targetValue);
        case "multiply":
          return (variableValue *= targetValue);
        case "divide":
          return (variableValue /= targetValue);
        default:
          return variableValue;
      }
    },
    handleSaveFormProgress: debounce(function () {
      if (!this.styling.enableResumption) return;
      const formDataObject = {};
      this.formElements.forEach((el) => {
        if (!isEmpty(el.children)) {
          this.setNestedValues(formDataObject, el.children);
        }

        if (el.type === "repeat-field") {
          formDataObject[el.id] = el.rows.map((row, i) => {
            const rowData = {};
            row.forEach((col) => {
              rowData[col.id] = col.value;
            });

            return rowData;
          });

          return;
        }

        if (!el.value) return;

        if (el.type === "file") {
          const value = [];
          el.value.forEach((file) => {
            const fileObject = {
              name: file.name,
              type: file.type,
              size: file.size,
              lastModified: file.lastModified,
            };

            value.push(fileObject);
          });

          formDataObject[el.id] = value;
          return;
        }

        if (
          el.type === "multiple-checkbox" &&
          el.hasCustomOption &&
          el.isCustomOptionChecked &&
          el.customOptionValue
        ) {
          formDataObject[el.id] = el.value.concat(el.customOptionValue);
          return;
        }

        formDataObject[el.id] = el.value;
      });

      localStorage.setItem(this.uuid, JSON.stringify(formDataObject));
    }, 3500),
    handleFormValueChange(item) {
      if (this.isComponentMounted) {
        // update the tempFormData
        this.appendElementToFormData(item, this.tempFormData);

        this.handleFirstInteraction();
        this.handleShowHideRules();
        this.handleCalculationRules();
        this.handleSaveFormProgress();
        this.handlePartialSubmission();
      }
    },
    handleCloseResumeSubmissionModal() {
      this.$refs.resumeSubmissionModal.close();
    },
    resetSubmission() {
      localStorage.removeItem(this.uuid);
      location.reload();
    },
    handleCountdown() {
      // remove countdown in thankyou page
      if (this.currentPageId === this.thankYouPageId) {
        this.$refs.countdown.stopCountdown();
        return;
      }

      // If timerStartPageId is set, start countdown only on that page
      if (this.timerStartPageId) {
        if (this.currentPageId === this.timerStartPageId) {
          this.$refs.countdown.startCountdown();
        }
        return;
      }

      // start countdown after welcome page
      if (this.currentPageId !== this.welcomePageId) {
        this.$refs.countdown.startCountdown();
      }
    },
    handleForceSubmit() {
      this.onSubmit(undefined, true);
    },
    onUnload() {
      if (this.styling.enablePartialSubmission || this.settings.saveAndResume) {
        this.saveDraftSubmission();
      }
    },
    handlePartialSubmission: debounce(async function () {
      if (this.styling.enablePartialSubmission || this.settings.saveAndResume) {
        await this.saveDraftSubmission();
      }
    }, 5000),
    async saveDraftSubmission() {
      if (this.submitting || this.submitted) return;

      try {
        await upsertDraftSubmission(
          this.uuid,
          this.formSessionId,
          this.tempFormData,
        );

        // clear tempFormData so that it doesn't override the same data again.
        this.clearTempFormData();
      } catch (error) {
        console.log(error);
      }
    },
    setPageHeight() {
      this.$nextTick(() => {
        if (!this.isIframe) return;

        const currentPageHeight =
          this.$refs.currentPageDiv?.scrollHeight || 300;
        const extraHeight = Math.max(this.styling.logoSize, 136);
        const totalHeight = currentPageHeight + extraHeight;

        window.top.postMessage({ id: this.uuid, height: totalHeight }, "*");
      });
    },
    addDefaultValueInVariables() {
      this.variables.forEach((variable) => {
        variable.defaultValue = variable.value;
      });
    },
    checkScriptToExecute() {
      const scripts = this.scripts.filter(
        (s) => s.target === this.currentPageId,
      );

      for (let script of scripts) {
        // QUESTION: should we again do validation over here as well?
        executeScriptsAndStyles(script.code, script.id);
      }
    },
    handleSaveDraft() {
      const draftKey = INTERNAL_QUERY_PARAMS.draft;
      const url = new URL(window.location.href);
      url.searchParams.set(draftKey, this.formSessionId);
      set(
        this.settings,
        "surveyUrl",
        `${this.surveyUrl}?${draftKey}=${this.formSessionId}`,
      );
      window.history.pushState({}, "", url);

      this.$refs.saveDraftModal.open();
    },
    copySurveyLink() {
      const surveyUrl = this.settings.surveyUrl;
      navigator.clipboard.writeText(surveyUrl);

      ElNotification({
        message: "Link copied to clipboard",
        type: "success",
        position: "bottom-right",
      });
    },
    async sendResumeLinkToEmail() {
      const emailInput = this.$refs.saveDraftEmailInput;
      if (!emailInput.checkValidity()) {
        emailInput.reportValidity();
        return;
      }

      const email = emailInput.value;

      if (!email) {
        ElNotification({
          message: "Please enter a valid email address",
          type: "error",
          position: "bottom-right",
        });
        return;
      }

      const data = {
        email,
        surveyUrl: this.settings.surveyUrl,
      };

      try {
        this.isSendingEmail = true;
        await emailSurveyLink(this.uuid, data);
        ElNotification({
          message: "Email sent successfully",
          type: "success",
          position: "bottom-right",
        });
      } catch (error) {
        ElNotification({
          message: "Failed to send email",
          type: "error",
          position: "bottom-right",
        });
      } finally {
        this.isSendingEmail = false;
      }
    },
  },
};
</script>

<style>
.form-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;
  min-height: 100%;
}
.form-logo {
  z-index: 2;
  margin: 20px;
}
.spin {
  width: 40px;
  height: 40px;
}

.non-editing {
  padding: 10px 16px;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
  container-type: inline-size;
}
.non-editing.child-component {
  padding: 6px 8px;
}

.expand-collapse-move,
.expand-collapse-enter-active,
.expand-collapse-leave-active {
  transition: all 0.3s ease;
}

.expand-collapse-enter-from {
  opacity: 0;
  overflow: hidden;
}

.next-page-leave-active,
.prev-page-leave-active {
  display: none;
}

form:has(.next-page-enter-active),
form:has(.prev-page-enter-active) {
  overflow: hidden;
}

.next-page-enter-active {
  animation: newPage 0.3s ease;
}

.prev-page-enter-active {
  animation: prevPage 0.3s ease;
}

@keyframes elementLeave {
  0% {
    opacity: 1;
  }

  100% {
    height: 0;
    opacity: 0;
    padding-block: 0;
  }
}

@keyframes newPage {
  0% {
    opacity: 0;
    transform: translateX(100px);
    position: absolute;
  }

  75% {
    transform: translateX(-5px);
    position: absolute;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    position: relative;
  }
}

@keyframes prevPage {
  0% {
    opacity: 0;
    transform: translateX(-100px);
    position: absolute;
  }

  75% {
    transform: translateX(5px);
    position: absolute;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    position: relative;
  }
}

.resume-submission-modal__content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.resume-submission-text {
  color: #101828;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
}

.alert-icon__wrapper {
  border-radius: 50%;
  background: #fef0c7;
}

.alert__icon {
  border-radius: 50%;
  border: 8px solid #fffaeb;
  padding: 12px;
  background: #fef0c7;
}

.resume-submission-modal__btn {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 10px 18px;
  border-radius: 4px;
}

.button__primary {
  background: #6434d0;
  color: #fff;
  border: 1px solid #6434d0;
  border-radius: 4px;
}

.button__primary:hover,
.button__primary:focus,
.button__primary:active {
  color: white;
  background-color: #5b4894;
}

.button__secondary {
  border: 1px solid #d0d5dd;
  background: #fff;
  color: #475467;
}

.button__secondary:hover,
.button__secondary:focus,
.button__secondary:active {
  background-color: #fafafa;
  border: 1px solid #57575745;
  color: #333;
}

.save-draft-modal__label {
  color: var(--color-primary-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  gap: var(--spacing-2);
}

.save-draft-modal__btn {
  width: 68px;
  flex-shrink: 0;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--rounded);
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-1);
}

.save-draft-modal__btn:hover {
  background: var(--primary-color-seventy-opacity);
}

.save-draft-modal__input {
  width: 100%;
  outline: none;
  padding: 10px 12px;
  border-radius: var(--rounded);
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow-xs);
  color: var(--color-primary-text);
}

@media only screen and (max-width: 768px) {
  .non-editing {
    padding: 6px 10px;
  }
}

@media only screen and (max-width: 576px) {
  .scroll-up-with-image {
    overflow: visible !important;
    height: auto !important;
    flex-grow: 1;
  }
  .space-for-branding {
    padding-bottom: v-bind(addBrandingSpace) !important;
  }
}
</style>
