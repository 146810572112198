import { ref } from "vue";

import { getDraftSubmissionData } from "@/api/draft_submissions";
import INTERNAL_QUERY_PARAMS from "@/constants/internalQueryParams";

export function useSaveAndResumeSubmission(formUuid, sessionId) {
  // State
  const formSessionId = ref(sessionId);
  const query = new URLSearchParams(window.location.search);
  const draftId = query.get(INTERNAL_QUERY_PARAMS.draft);
  const tempFormData = ref(new FormData()); // To store the temporary data which needs to be synced with the draft submission (backend)

  // Methods
  async function getDraftSubmission() {
    if (!draftId) return;

    formSessionId.value = draftId;
    try {
      const { data } = await getDraftSubmissionData(formUuid, draftId);
      return data;
    } catch (error) {
      console.warn("Could not fetch draft submission data:", error);
    }
  }

  function clearTempFormData() {
    tempFormData.value = new FormData();
  }

  return {
    formSessionId,
    getDraftSubmission,
    tempFormData,
    clearTempFormData,
  };
}
