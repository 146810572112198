import { get, post, put } from "./_utils";

export const upsertDraftSubmission = (formUuid, submissionId, data) => {
  return post(
    `/forms/${formUuid}/submissions.json?draft_id=${submissionId}&status=incomplete`,
    data,
  );
};

export const getDraftSubmissionData = (formUuid, draftSubmissionId) => {
  return get(`/forms/${formUuid}/submissions/${draftSubmissionId}/draft.json`);
};
