import { liveQuery } from "dexie";
import axios from "axios";
import { db } from "./db";

export default class OfflineModeFormController {
  async saveSubmission(formData, action) {
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    await db.submissions.add({ data: formDataObject, action: action });
    console.debug("Form data saved to IndexedDB");
  }

  async syncSubmissions() {
    try {
      const submissions = await db.submissions.toArray();

      if (submissions.length < 1) return;

      // Convert each stored plain object back to FormData
      submissions.forEach(async (submission) => {
        const formData = new FormData();
        for (const key in submission.data) {
          formData.append(key, submission.data[key]);
        }

        // Post the form data to the server
        try {
          const submission_data = await db.submissions.get(submission.id);
          if (!submission_data) return;
          await axios.post(submission.action, formData, {
            params: {
              status: "complete",
              draft_id: submission_data.data.session_id,
            },
          });

          console.debug("Submission posted to server");

          // Delete the record from IndexedDB after successful sync
          await db.submissions.delete(submission.id);
        } catch (postError) {
          console.error("Error posting form data:", postError);
        }
      });

      window.postMessage({ type: "synced" });
    } catch (error) {
      console.error("Error getting submissions:", error);
      return [];
    }
  }

  observeSubmissionCount() {
    try {
      return liveQuery(() => db.submissions.count());
    } catch (error) {
      console.error("Error observing submission count:", error);
    }
  }
}
